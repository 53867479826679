import axios, { AxiosResponse } from 'axios';
import { NewsletterSubscriptionRequest } from '@/models/Content';

export default class NewsletterService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/newsletter`;
    }

    public async canSubscribe(): Promise<boolean> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/cansubscribe`);           
            return res.data;
        } catch (e) {
            return false;
        }
    }

    public async subscribe(payload: NewsletterSubscriptionRequest): Promise<boolean> {
        try {
            await axios.post(`${this.endpoint}/subscribe`, payload);           
            return true;
        } catch (e) {
            return false;
        }
    }

    public async unsubscribe(payload: NewsletterSubscriptionRequest): Promise<boolean> {
        try {
            axios.post(`${this.endpoint}/unsubscribe`, payload);
            return true;
        } catch (e) {
            return false
        }
    }

    public async getLists(): Promise<string[]> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/listids`);
            return res.data;
        } catch (e) {
            return null;
        }
    }
}
